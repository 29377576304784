/* eslint-disable wix-custom-rules/wixstores-file-names */
import {ShouldRenderVarsFnParams} from '../../baseCustomCssVars';
import {CategoryListItemsToShow, CategoryListLayout} from '../../constants';

/* istanbul ignore file */
export const extendedGridGalleryShouldRenderVars = ({numbers, booleans, isMobile}: ShouldRenderVarsFnParams) => {
  const verticalCategoryList = numbers.gallery_categoryListLayout === CategoryListLayout.Vertical && !isMobile;
  const {gallery_showCategories, galleryShowFilters} = booleans;
  const includeSiblingsInCategoryList = numbers.gallery_categoryListItemsToShow === CategoryListItemsToShow.ShowAll;

  return {
    showSideFilters: (gallery_showCategories && verticalCategoryList) || galleryShowFilters,
    showHorizontalCategoryList: gallery_showCategories && !verticalCategoryList,
    showHorizontalCategoryListWhenNoSubcategories:
      gallery_showCategories && !verticalCategoryList && includeSiblingsInCategoryList,
  };
};
