import {CustomCssVarsFnParams, ShouldRenderVarsFnParams} from '../../../baseCustomCssVars';
import {CategoryListHorizontalOverflow, CategoryListLayout} from '../../../constants';
import {CategoryListHeaderBehavior, LongCategoryListLayout} from '../../../types/galleryTypes';

/* istanbul ignore file */
export const categoryTreeCustomCssVars = ({styleParams: styles, isMobile}: CustomCssVarsFnParams) => {
  const shouldIndentCategoryList =
    styles.booleans.gallery_showCategoryListHeader &&
    styles.numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT;

  const shouldLimitLongCategoryList = styles.numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT;
  const verticalCategoryList = styles.numbers.gallery_categoryListLayout === CategoryListLayout.Vertical && !isMobile;

  return {
    shouldLimitLongCategoryList: shouldLimitLongCategoryList ? 1 : 0,
    collapsedCategoryListDisplay: shouldLimitLongCategoryList ? '-webkit-box' : 'block',
    categoryListIndentationPx: verticalCategoryList && shouldIndentCategoryList ? '28px' : '0px',
    categoryListMarginBottom: verticalCategoryList ? '12px' : '0',
    horizontalCategoryListFlexWrap:
      styles.numbers.gallery_categoryListHorizontalOverflow === CategoryListHorizontalOverflow.Scroll
        ? 'nowrap'
        : 'wrap',
    categoryListItemBorderWidth: verticalCategoryList ? 0 : 'unset',
    categoryListItemHorizontalPadding: verticalCategoryList
      ? 0
      : styles.numbers.gallery_categoryListHorizontalButtonsHorizontalPadding,
    categoryListItemVerticalPadding: verticalCategoryList
      ? 0
      : styles.numbers.gallery_categoryListHorizontalButtonsVerticalPadding,
    categoryListItemMinWidth: verticalCategoryList ? 0 : 'unset',
    categoryListItemColor: verticalCategoryList
      ? styles.colors.gallery_filterOptionsColor
      : styles.colors.gallery_categoryListItemColor,
    categoryListItemHoverColor: verticalCategoryList
      ? styles.colors.gallery_categoryHoverColor
      : styles.colors.gallery_categoryListItemColor,
  };
};

export const categoryTreeShouldRenderVars = ({numbers}: ShouldRenderVarsFnParams) => {
  return {
    categoryListHeaderGoesBackToParent:
      numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT,
    categoryListHeaderDoesNothing: numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.DO_NOTHING,
    shouldLimitCategoryList: numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT,
  };
};
